import { useEffect, useState, useRef } from "react";
import type { Timer } from "../Models/Timer";
import { PrimaryButton } from "./PrimaryButton";
import EventEmitter from "../Utils/EventEmitter";
import { IoCloseCircle } from "react-icons/io5";
import { UpdateTimerData } from "../Views/TimersView";
import { PlayNotificationSound } from "../Utils/SoundManager";
import DynamicInputField from "./DynamicInputField";

interface TimerProps {
  timer: Timer;
}

enum ButtonsPlacement {
  Outside,
  Right,
}

export default function TimerView(props: TimerProps) {
  const [showTitle, setShowTitle] = useState(false);
  const [showTitleControls, setShowTitleControls] = useState(false);

  const [subtitle, setSubtitle] = useState(props.timer.subtitle);
  const [showButtons, setShowButtons] = useState(false);
  const [buttonsPlacement, setButtonsPlacement] = useState(ButtonsPlacement.Outside);
  const [areButtonsHorizontal, setAreButtonsHorizontal] = useState(false);
  const [isAnimatingButtons, setIsAnimatingButtons] = useState(false);

  const [isRunning, setIsRunning] = useState(false);

  const [progress, setProgress] = useState(0);
  const [totalSeconds, setTotalSeconds] = useState(props.timer.totalSeconds);
  const totalSecondsRef = useRef(props.timer.totalSeconds);
  const [elapsedSeconds, setElapsedSeconds] = useState(props.timer.elapsedSeconds);
  const elapsedSecondsRef = useRef(props.timer.elapsedSeconds);

  const passedFiveMinThreshold = useRef(false);
  const [isFiveMinLeft, setIsFiveMinLeft] = useState(false);

  const isRunningRef = useRef(isRunning);
  const digitalClockRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const tickListener = EventEmitter.addListener("OnTick", () => {
      if (!isRunningRef.current) return;

      setElapsedSeconds((prevElapsedSeconds) => {
        const updatedElapsedSeconds = prevElapsedSeconds + 1;

        let newProgress = updatedElapsedSeconds / totalSecondsRef.current;
        if (newProgress >= 1) {
          ToggleRunning(false);
          PlayNotificationSound();
        }
        setProgress(newProgress);
        elapsedSecondsRef.current = updatedElapsedSeconds;

        const lessThanFiveMinLeft = totalSecondsRef.current - updatedElapsedSeconds <= 60 * 5 && totalSecondsRef.current > 60 * 5;
        setIsFiveMinLeft(lessThanFiveMinLeft);
        if (lessThanFiveMinLeft && !passedFiveMinThreshold.current) {
          passedFiveMinThreshold.current = true;
          PlayNotificationSound();
        }

        UpdateTimerData(props.timer.id, (timer) => {
          timer.elapsedSeconds = elapsedSecondsRef.current;
        });
        return updatedElapsedSeconds;
      });
    });

    const globalRunListener = EventEmitter.addListener("toggleGlobalRun", (isGlobalRunning: boolean) => {
      if (totalSecondsRef.current <= 0 || totalSecondsRef.current <= elapsedSecondsRef.current) return;

      ToggleRunning(isGlobalRunning);
    });

    const resizeObserver = new ResizeObserver((entries) => {
      handleClockResize();
      HandleContainerResize();
    });

    if (digitalClockRef.current) {
      resizeObserver.observe(digitalClockRef.current);
    }
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    function handleClockResize() {
      const containerWidth = digitalClockRef.current?.offsetWidth ?? 250;

      const clockFontSize = containerWidth / 3.5;
      digitalClockRef.current?.style.setProperty("font-size", `${clockFontSize}px`);
    }

    function HandleContainerResize() {
      if (!containerRef.current) return;

      const aspecRatio = containerRef.current?.offsetWidth / containerRef.current?.offsetHeight;

      setButtonsPlacement(aspecRatio > 1 ? ButtonsPlacement.Right : ButtonsPlacement.Outside);
      setAreButtonsHorizontal(aspecRatio > 0.7);
    }

    handleClockResize();

    return () => {
      tickListener.remove();
      globalRunListener.remove();
      resizeObserver.disconnect();
    };
  }, []);

  const OnMouseEnter = () => {
    setShowButtons(true);
    setIsAnimatingButtons(true);
  };
  const OnMouseLeave = () => {
    setShowButtons(false);
    setIsAnimatingButtons(true);
  };
  const OnTransitionEnd = () => {
    setIsAnimatingButtons(false);
  };

  const ToggleRunning = (isOn?: boolean) => {
    let toggledIsRunning = isOn ?? !isRunningRef.current;
    setIsRunning(toggledIsRunning);
    isRunningRef.current = toggledIsRunning;
    UpdateTimerData(props.timer.id, (timer) => {
      timer.isRunning = toggledIsRunning;
    });
  };

  const Reset = () => {
    setElapsedSeconds(0);
    elapsedSecondsRef.current = 0;
    setProgress(0);
    setIsFiveMinLeft(false);
    passedFiveMinThreshold.current = false;
    UpdateTimerData(props.timer.id, (timer) => {
      timer.elapsedSeconds = 0;
    });
  };

  function RemoveTimer() {
    EventEmitter.emit("OnRemoveTimer", props.timer);
  }

  const OnMouseEnterTitle = () => {
    if (isRunning) return;
    setShowTitleControls(true);
  };
  const OnMouseLeaveTitle = () => {
    setShowTitleControls(false);
  };

  function EditHours(input: string) {
    const hours: number = input == "" ? 0 : parseInt(input);
    if (isNaN(hours) || hours > 23) return;

    const newTotalSeconds = hours * 3600 + (totalSecondsRef.current % 3600);
    totalSecondsRef.current = newTotalSeconds;
    setTotalSeconds(newTotalSeconds);
    UpdateTimerData(props.timer.id, (timer) => {
      timer.totalSeconds = newTotalSeconds;
    });
    Reset();
  }

  function EditMinutes(input: string) {
    const minutes = input == "" ? 0 : parseInt(input);
    if (isNaN(minutes) || minutes > 59) return;

    const newTotalSeconds = totalSecondsRef.current - (totalSecondsRef.current % 3600) + minutes * 60;
    totalSecondsRef.current = newTotalSeconds;
    setTotalSeconds(newTotalSeconds);
    UpdateTimerData(props.timer.id, (timer) => {
      timer.totalSeconds = newTotalSeconds;
    });
    Reset();
  }

  return (
    <>
      <div
        ref={containerRef}
        className={`basis-0 max-w-lg max-h-[90%] grow relative flex flex-col items-center gap-8 p-8 rounded-3xl duration-300 ease-out ${isRunning ? "scale-[0.98]" : "shadow-1.5xl-c"}`}
        onMouseEnter={OnMouseEnter}
        onMouseLeave={OnMouseLeave}
        onTransitionEnd={OnTransitionEnd}
      >
        <IoCloseCircle
          onClick={RemoveTimer}
          className={`absolute right-4 top-4 text-2xl text-neutral-300 hover:text-red-600 active:opacity-70 duration-200 cursor-pointer ${showButtons && !isRunning ? "opacity-100" : "opacity-0 pointer-events-none"}`}
        />

        {showTitle ? (
          <div className="text-center -mb-8" onMouseEnter={OnMouseEnterTitle} onMouseLeave={OnMouseLeaveTitle}>
            <DynamicInputField
              className={`w-full font-semibold text-center bg-transparent outline-none h-10 md:h-12 lg:h-16`}
              value={props.timer.title}
              placeholder={"Title"}
              OnValueChange={function (newValue: string): void {
                UpdateTimerData(props.timer.id, (timer) => {
                  timer.title = newValue;
                });
              }}
            />

            <DynamicInputField
              className={`w-full font-semibold bg-transparent text-center outline-none h-6 md:h-8 lg:h-10`}
              value={props.timer.subtitle}
              placeholder={"Subtitle"}
              OnValueChange={function (newValue: string): void {
                UpdateTimerData(props.timer.id, (timer) => {
                  timer.subtitle = newValue;
                });
              }}
            />

            <button className={`${showTitleControls ? "opacity-100" : "opacity-0 pointer-events-none"} h-8 duration-300 text-center text-gray-500 hover:text-red-600`} onClick={() => setShowTitle(false)}>
              {" "}
              Remove Title{" "}
            </button>
          </div>
        ) : (
          <button
            className={`${
              isRunning || !showButtons ? "opacity-0 pointer-events-none" : "opacity-100"
            } text-md md:text-lg lg:text-xl overflow-clip whitespace-nowrap  w-1/2 h-10 duration-300 text-center bg-gray-100 hover:bg-gray-200 text-gray-400 rounded-md`}
            onClick={() => setShowTitle(true)}
          >
            Add Title
          </button>
        )}

        <div className="flex h-full w-full relative gap-4">
          <div className={`flex-grow w-full relative ${!showButtons && buttonsPlacement == ButtonsPlacement.Right ? "left-1/2 -translate-x-1/2" : "left-0 translate-x-0"} ${isAnimatingButtons && "duration-300"}`}>
            <div
              className="absolute max-w-full max-h-full aspect-square top-0 left-0 bottom-0 right-0 m-auto rounded-full p-2"
              style={{
                background: progressGradient(progress, isRunning),
              }}
            >
              <div ref={digitalClockRef} className={`bg-white ${isFiveMinLeft && "text-red-700"} rounded-full w-full h-full flex justify-center items-center z-10 ${isRunning && "pointer-events-none"}`}>
                <input
                  className="outline-none bg-transparent text-right w-1/2"
                  type="number"
                  value={hoursLeft(totalSeconds, elapsedSeconds)}
                  onChange={(e) => {
                    EditHours(e.currentTarget.value);
                  }}
                />
                <div className={`select-none pb-[5%] ${showSeparator(elapsedSeconds) || !isRunning ? "opacity-100" : "opacity-0"}`}>:</div>
                <input
                  className="outline-none bg-transparent text-left w-1/2"
                  type="number"
                  value={minutesLefts(totalSeconds, elapsedSeconds)}
                  onChange={(e) => {
                    EditMinutes(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
          </div>
          {buttonsPlacement == ButtonsPlacement.Right && (
            <div className={`${showButtons ? "opacity-100" : "opacity-0"} min-w-[150px] duration-300 flex flex-col gap-4 h-full justify-center`}>
              <PrimaryButton disabled={totalSeconds == 0 || elapsedSeconds >= totalSeconds} onClick={() => ToggleRunning()}>
                {" "}
                {isRunning ? "Pause" : "Start"}{" "}
              </PrimaryButton>
              <PrimaryButton className={`${isRunning ? "opacity-0 pointer-events-none" : "opacity-100"}`} onClick={Reset}>
                Reset
              </PrimaryButton>
            </div>
          )}
        </div>
        {buttonsPlacement == ButtonsPlacement.Outside && (
          <div className={`${showButtons ? "opacity-100" : "opacity-0"} duration-300 flex ${areButtonsHorizontal ? "min-w-[300px]" : "flex-col min-w-[150px]"} gap-4`}>
            <PrimaryButton disabled={totalSeconds == 0 || elapsedSeconds >= totalSeconds} onClick={() => ToggleRunning()} className="flex-1">
              {" "}
              {isRunning ? "Pause" : "Start"}{" "}
            </PrimaryButton>
            <PrimaryButton className={`${isRunning ? "opacity-0 pointer-events-none" : "opacity-100"} flex-1`} onClick={Reset}>
              Reset
            </PrimaryButton>
          </div>
        )}
      </div>
    </>
  );
}

function progressGradient(progress: number, isRunning: boolean): string {
  if (progress == 0 && !isRunning) {
    return `conic-gradient(lightgray 0deg, lightgray 360deg)`;
  }
  progress = progress * 360;
  const primaryColor = "#f59e0b";
  return `conic-gradient(lightgray 0deg, lightgray ${progress}deg, ${primaryColor} ${progress}deg, ${primaryColor} 360deg)`;
}

function hoursLeft(totalSeconds: number, elapsedSeconds: number): string {
  let hoursLeft = Math.floor((totalSeconds - elapsedSeconds) / 3600);
  let minutesLeft = Math.ceil(((totalSeconds - elapsedSeconds) % 3600) / 60);
  if (minutesLeft == 60) {
    hoursLeft += 1;
  }
  return `${hoursLeft < 10 ? "0" : ""}${hoursLeft}`;
}

function minutesLefts(totalSeconds: number, elapsedSeconds: number): string {
  let minutesLeft = Math.ceil(((totalSeconds - elapsedSeconds) % 3600) / 60);
  if (minutesLeft == 60) {
    minutesLeft = 0;
  }
  return `${minutesLeft < 10 ? "0" : ""}${minutesLeft}`;
}

function showSeparator(elapsedSeconds: number): boolean {
  return elapsedSeconds % 2 == 0;
}
