import Cookies from "universal-cookie";

let password = "fs-15153";

const cookies = new Cookies();

export function validateLogin(enteredPassword: string): boolean {
  const isValid = enteredPassword === password;
  if (isValid) {
    cookies.set("isAuthenticated", true, { maxAge: 60 * 60 * 24 });
  } else {
    cookies.remove("isAuthenticated");
  }
  return isValid;
}
