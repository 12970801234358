interface PrimaryButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

export function PrimaryButton(props: PrimaryButtonProps) {
  let isChildString = typeof props.children === "string" || Array.isArray(props.children);

  return (
    <button
      disabled={props.disabled}
      className={`border-primary-500 border-2 bg-transparent hover:bg-primary-500 text-black ${
        isChildString ? "text-md font-semibold" : "text-2xl"
      } hover:text-white duration-200 py-1 px-4 rounded-full active:opacity-70 h-9 disabled:opacity-50 disabled:hover:bg-transparent disabled:hover:text-black ${props.className}`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
