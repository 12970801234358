import EventEmitter from "../Utils/EventEmitter";
import { useEffect, useState } from "react";
import TimerView from "../Elements/TimerView";
import { Timer } from "../Models/Timer";

export let timers: Timer[] = [];

export function UpdateTimerData(forID: string, update: (timer: Timer) => void) {
  let timer = timers.find((t) => t.id == forID);
  if (timer) {
    update(timer);
    EventEmitter.emit("OnTimerDataChange", timer);
  }
}

export default function TimersViews() {
  const [stateTimers, setStateTimers] = useState(timers);

  useEffect(() => {
    const addTimerListener = EventEmitter.addListener("OnAddTimer", () => {
      AddTimer();
    });

    const RemiveTimerListener = EventEmitter.addListener("OnRemoveTimer", (timer: Timer) => {
      RemoveTimer(timer);
    });

    function AddTimer() {
      timers.push(new Timer());
      setStateTimers([...timers]);
    }

    function RemoveTimer(timer: Timer) {
      let index = timers.indexOf(timer);
      if (index > -1) {
        timers.splice(index, 1);
      }
      setStateTimers([...timers]);
    }

    return () => {
      addTimerListener.remove();
      RemiveTimerListener.remove();
    };
  }, []);

  return (
    <>
      <div className="flex justify-center gap-10 mx-10 grow">
        {stateTimers.map((timer) => (
          <TimerView key={timer.id} timer={timer} />
        ))}

        {stateTimers.length === 0 && <div className="text-2xl text-center text-gray-300 mt-[20vh] select-none">You don't have any timers yet. Click the Add Timer button in the rop right corner to start.</div>}
      </div>
    </>
  );
}
