export class Timer {
    id: string

    title: string
    subtitle: string

    totalSeconds: number
    elapsedSeconds: number = 0

    isRunning: boolean = false

    constructor(id: string = UUID(), title: string = "", subtitle: string = "", totalSeconds: number = 0) {
        this.id = id
        this.title = title
        this.subtitle = subtitle
        this.totalSeconds = totalSeconds
    }
}

function UUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0,
        v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    })
}