import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { PrimaryButton } from "./Elements/PrimaryButton";
import { validateLogin } from "./Utils/Auth";
import { Header } from "./Views/Header";
import TitleView from "./Views/TitleView";
import TimersViews from "./Views/TimersView";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    setIsAuthenticated(cookies.get("isAuthenticated"));
  }, []);

  if (!isAuthenticated) return <Login setIsAuthenticated={setIsAuthenticated} />;
  return <AppView />;
}

export default App;

const AppView = () => {
  return (
    <div className="flex flex-col h-screen w-screen">
      <Header />
      <TitleView />
      <TimersViews />
    </div>
  );
};

const Login = ({ setIsAuthenticated }: { setIsAuthenticated: (isAuth: boolean) => void }) => {
  const [enteredPassword, setEnteredPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  function CheckPassword() {
    let isValid = validateLogin(enteredPassword);
    setIsPasswordValid(isValid);
    setIsAuthenticated(isValid);
  }

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen gap-y-10">
      <h1 className="text-3xl font-semibold">Corpus Timer</h1>
      <input
        className="text-center outline-none text-xl"
        type="password"
        placeholder="Enter password"
        value={enteredPassword}
        onChange={(e) => {
          setEnteredPassword(e.currentTarget.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") CheckPassword();
        }}
      />
      <PrimaryButton onClick={CheckPassword}>Log-in</PrimaryButton>
      <p className={`text-red-500 ${isPasswordValid ? "opacity-0" : "opacity-100"}`}>Incorrect password</p>
    </div>
  );
};
