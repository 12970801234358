import { useState, useEffect, useRef } from "react";

interface DynamicInputFieldProps {
  className?: string;
  value: string;
  placeholder: string;
  OnValueChange?(newValue: string): void;
}

export default function DynamicInputField(props: DynamicInputFieldProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(props.value);

  function OnChange(e: React.ChangeEvent<HTMLInputElement>) {
    props.OnValueChange?.(e.currentTarget.value);
    setInputValue(e.currentTarget.value);
    resizeFont();
  }

  function getTextWidth(text: string, font: string) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (!context) return 0;

    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  function resizeFont() {
    if (!inputRef.current) return;

    const inputWidth = inputRef.current.clientWidth - 16;
    const inputHeight = inputRef.current.clientHeight * 0.75;
    const fontFamily = window.getComputedStyle(inputRef.current).getPropertyValue("font-family");
    let fontSize = parseInt(window.getComputedStyle(inputRef.current).getPropertyValue("font-size"));

    // Increase the font size if there's enough space
    while (true) {
      const newFontSize = fontSize + 1;
      const newTextWidth = inputRef.current.value == "" ? getTextWidth("0", `${newFontSize}px ${fontFamily}`) : getTextWidth(inputRef.current.value, `${newFontSize}px ${fontFamily}`);
      console.log(newFontSize, inputHeight);
      if (newTextWidth < inputWidth && newFontSize < inputHeight) {
        fontSize = newFontSize;
      } else {
        break;
      }
    }

    // Decrease the font size if the text is too large
    while (getTextWidth(inputRef.current.value, `${fontSize}px ${fontFamily}`) > inputWidth && fontSize > 10) {
      fontSize -= 1;
    }

    inputRef.current.style.fontSize = `${fontSize}px`;
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      resizeFont();
    });

    if (inputRef.current) {
      resizeObserver.observe(inputRef.current);
    }

    resizeFont();

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <>
      <input ref={inputRef} className={props.className} value={inputValue} placeholder={props.placeholder} onChange={OnChange} />
    </>
  );
}
