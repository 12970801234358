import { useEffect, useState } from "react";
import { TbAlarmPlus, TbVolume, TbVolumeOff } from "react-icons/tb";
import { PrimaryButton } from "../Elements/PrimaryButton";
import EventEmitter from "../Utils/EventEmitter";
import { timers } from "./TimersView";
import type { Timer } from "../Models/Timer";
import { PlayNotificationSound, ToggleSound } from "../Utils/SoundManager";

export function Header() {
  const [stateUpdater, setStateUpdater] = useState(0);

  const [isGlobalRunning, setIsGlobalRunning] = useState(false);
  const [isGlobalRunButtonActive, setIsGlobalRunButtonActive] = useState(false);
  const [tickDuration, setTickDuration] = useState(1);
  const [addTimerDisabled, setAddTimerDisabled] = useState(false);

  const [isSoundOn, setIsSoundOn] = useState(true);

  const ToggleGlobalSound = () => {
    const isOn = !isSoundOn;
    setIsSoundOn(isOn);
    ToggleSound(isOn);
  };

  const ToggleGlobalRun = () => {
    let updatedIsGlobalRunning = !isGlobalRunning;
    setIsGlobalRunning(updatedIsGlobalRunning);
    EventEmitter.emit("toggleGlobalRun", updatedIsGlobalRunning);

    PlayNotificationSound(true);
  };

  const AddTimer = () => {
    EventEmitter.emit("OnAddTimer");
    setAddTimerDisabled(timers.length >= 4);
    UpdateStates();
  };

  function SetTickDuration(value: string) {
    let tick = parseFloat(value);
    setTickDuration(tick);
    EventEmitter.emit("OnTickDurationChange", tick);
  }

  function UpdateStates() {
    setIsGlobalRunning(timers.length > 0 && timers.every((timer) => timer.isRunning || timer.elapsedSeconds >= timer.totalSeconds) && !timers.every((timer) => timer.totalSeconds == timer.elapsedSeconds));
    setIsGlobalRunButtonActive(timers.length > 0 && timers.some((timer) => timer.totalSeconds > 0 && timer.elapsedSeconds < timer.totalSeconds));
  }

  useEffect(() => {
    const interval = setInterval(() => {
      EventEmitter.emit("OnTick");
    }, tickDuration * 1000);

    const RemoveTimerListener = EventEmitter.addListener("OnRemoveTimer", () => {
      setAddTimerDisabled(timers.length >= 4);
      setStateUpdater((prev) => prev + 1);
    });

    const TimerDataChangeListener = EventEmitter.addListener("OnTimerDataChange", (timer: Timer) => {
      setStateUpdater((prev) => prev + 1);
    });

    return () => {
      clearInterval(interval);
      RemoveTimerListener.remove();
      TimerDataChangeListener.remove();
    };
  });

  useEffect(() => {
    setTimeout(() => {
      UpdateStates();
    }, 0);
  }, [stateUpdater]);

  return (
    <>
      <div className="flex h-12 justify-between items-center mx-4">
        <div>
          <PrimaryButton onClick={ToggleGlobalSound}>{isSoundOn ? <TbVolume /> : <TbVolumeOff />}</PrimaryButton>
        </div>
        <div className="flex items-center space-x-2">
          {/* <input type="number" className="w-12 h-8 rounded-md text-center outline-primary-500" placeholder="0.0" value={tickDuration} onChange={(e) => SetTickDuration(e.currentTarget.value)} /> */}
          <PrimaryButton disabled={addTimerDisabled} onClick={AddTimer}>
            <TbAlarmPlus />
          </PrimaryButton>
          <PrimaryButton disabled={!isGlobalRunButtonActive} className={`min-w-[120px]`} onClick={ToggleGlobalRun}>
            {" "}
            {isGlobalRunning ? "Pause All" : "Start All"}{" "}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
}
