
let isSoundOn: boolean = true;

let lastNotificationTime: number = 0;

export function PlayNotificationSound (force: boolean = false) {
    if (!isSoundOn) { return }
    if (Date.now() - lastNotificationTime < 500 && !force) { return }

    const audio = new Audio("/Assets/Sounds/Notification.mp3");
    audio.play();

    lastNotificationTime = Date.now();
}

export function ToggleSound(isOn: boolean) {
    isSoundOn = isOn;
}