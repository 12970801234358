import { useEffect, useRef, useState } from "react";
import dateFormat from "dateformat";

export default function TitleView() {
  const titleRef = useRef<HTMLInputElement>(null);

  const [title, setTitle] = useState("");
  const [showTitle, setShowTitle] = useState(false);
  const [showTitleControls, setShowTitleControls] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  });

  function OnMouseEnter() {
    setShowTitleControls(true);
  }
  function OnMouseLeave() {
    setShowTitleControls(false);
  }

  const updateInputWidth = (content: string) => {
    if (!titleRef.current) {
      return;
    }

    titleRef.current.style.width = "auto";
    const maxWidth = Math.floor(window.innerWidth * 0.9);
    const newWidth = Math.min(titleRef.current.scrollWidth, maxWidth);
    titleRef.current.style.width = `${newWidth}px`;
  };

  return (
    <>
      <div className="flex justify-center h-1/7 md:h-1/6 lg:h-1/5">
        <div className={`flex flex-col items-center justify-center h-full w-fit py-10`} onMouseEnter={OnMouseEnter} onMouseLeave={OnMouseLeave}>
          {showTitle ? (
            <input
              ref={titleRef}
              type="text"
              placeholder="Title"
              className={`${!showTitle && "opacity-0 pointer-events-none"} bg-transparent text-center font-semibold outline-none text-3xl md:text-4xl lg:text-5xl`}
              value={title}
              onChange={(e) => {
                setTitle(e.currentTarget.value);
                updateInputWidth(e.currentTarget.value);
              }}
            />
          ) : (
            <button
              className={`${
                !showTitle && showTitleControls ? "opacity-100" : "opacity-0 pointer-events-none"
              } text-md md:text-lg lg:text-xl overflow-clip whitespace-nowrap px-10 h-10 duration-300 text-center bg-gray-100 hover:bg-gray-200 text-gray-400 rounded-md`}
              onClick={() => setShowTitle(true)}
            >
              Add Title
            </button>
          )}

          <button className={`${showTitleControls && showTitle ? "opacity-100" : "opacity-0 pointer-events-none"} h-8 duration-300 text-center text-gray-500 hover:text-red-600`} onClick={() => setShowTitle(false)}>
            {" "}
            Remove Title{" "}
          </button>

          <div className="bg-transparent text-center select-none text-gray-500 text-lg md:text-xl lg:text-2xl">{FormattedDate(currentTime)}</div>
        </div>
      </div>
    </>
  );
}

function FormattedDate(date: Date): string {
  return dateFormat(date, "mmmm d, H:MM");
}
